import React from 'react';
import './Home.css';

function Home() {
  const currentDate = new Date();

  const registrationDeadline = new Date("11/6/2024");

  const difference = registrationDeadline.getTime() - currentDate.getTime();

  console.log(currentDate.getTime());

  let daysLeft = Math.ceil(difference / (1000 * 3600 * 24));

  let registrationMessage = `${daysLeft} days left to register`;

  if (daysLeft === 1) {
    registrationMessage = `1 day left to register!`
  } else if (daysLeft === 0) {
    registrationMessage = `Today's the deadline!`
  } else if (daysLeft < 0) {
    registrationMessage = `This event is over, stay tuned!`
  }

  return (
    <div className="Home">
      <table>
        <tr>
          <td rowspan="2"><img src="https://ik.imagekit.io/da1raj6v2/assets/abstract?updatedAt=1706863685534" alt="Abstract art" id="abstract" /></td>
          <td>
            <div className="header">
              <h1 id="header">The biggest student-led poetry slam in Dubai</h1>
              <h1 id="register-by"><span>Submit by</span> 6/11/24</h1>
              <h1 id="event-date"><span>Takes place</span> 8/11/24</h1>
            </div>
          </td>
          <td><img src="https://ik.imagekit.io/da1raj6v2/assets/impact?updatedAt=1713637897117" alt="IMPACT logo" id="impact" /></td>
        </tr>
        <tr>
          <td colspan="2" id="registration-message"><h2>{registrationMessage}</h2></td>
        </tr>
        <tr>
          <td colspan="3">
            <div className="extra-info">
              <h4>Submissions are compiled and published in paperback books</h4>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <h3>What is IMPACT?</h3>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <p>IMPACT is the biggest student-hosted poetry slam in Dubai– it's run by a dedicated group of 4 students from Dubai International Academy,
              Emirates Hills; through our events, we've published 2 books, over 100 poems, and proudly worked with over 
              60 student poets. Now, we're hosting an event open to all Innoventures' schools. Sign up by the 6th to have your poem compiled and 
              published– and get to recite your poem in front of other budding poets!</p>
          </td>
        </tr>
      </table>
    </div>
  );
}

export default Home;
